import gql from 'graphql-tag';
import { client } from '../data/api';

export const softDeleteUser = async (userId: number) => {
  await client.mutate({
    mutation: gql`
      mutation DeactivateUser($set: users_roles_set_input, $where: users_roles_bool_exp!) {
        update_users_roles(where: $where, _set: $set) {
          returning {
            users_id
          }
        }
      }
    `,
    variables: {
      where: {
        users_id: { _eq: userId },
      },
      set: {
        deleted_at: 'now()',
      },
    },
  });
  await client.mutate({
    mutation: gql`
      mutation DeleteNotifications($set: notifications_set_input, $where: notifications_bool_exp!) {
        update_notifications(where: $where, _set: $set) {
          returning {
            id
          }
        }
      }
    `,
    variables: {
      where: {
        user_id: { _eq: userId },
      },
      set: {
        active: false,
      },
    },
  });
  return client.mutate({
    mutation: gql`
      mutation DeleteUser($set: users_set_input, $where: users_bool_exp!) {
        update_users(where: $where, _set: $set) {
          returning {
            id
          }
        }
      }
    `,
    variables: {
      where: {
        id: { _eq: userId },
      },
      set: {
        deleted_at: 'now()',
      },
    },
  });
};

export const deactivateUser = async (userId: number, orgId: number) => {
  const { data } = await client.query({
    query: gql`
      query GetUserRoles($where: users_roles_bool_exp!) {
        portal_users_roles(where: $where) {
          organization_id
        }
      }
    `,
    variables: {
      where: {
        users_id: { _eq: userId },
      },
    },
  });
  const hasOnlyOneOrg = data.portal_users_roles.length === 1;

  if (hasOnlyOneOrg) {
    return softDeleteUser(userId);
  }
  await client.mutate({
    mutation: gql`
      mutation DeleteNotifications($set: notifications_set_input, $where: notifications_bool_exp!) {
        update_notifications(where: $where, _set: $set) {
          returning {
            id
          }
        }
      }
    `,
    variables: {
      where: {
        user_id: { _eq: userId },
        organization_id: { _eq: orgId },
      },
      set: {
        active: false,
      },
    },
  });
  return client.mutate({
    mutation: gql`
      mutation DeactivateUser($set: users_roles_set_input, $where: users_roles_bool_exp!) {
        update_users_roles(where: $where, _set: $set) {
          returning {
            users_id
          }
        }
      }
    `,
    variables: {
      where: {
        organization_id: { _eq: orgId },
        users_id: { _eq: userId },
      },
      set: {
        deleted_at: 'now()',
      },
    },
  });
};
