import { Box, Chip, Typography } from '@mui/material';
import { Confirm, FunctionField, SelectField, useRecordContext, useRefresh } from 'react-admin';

import { OrganizationReferenceField } from '../organizations/OrganizationReferenceField';
import { USER_ROLE_CHOICES } from './UserEnums';
import Button from '@mui/material/Button';
import * as React from 'react';
import { deactivateUser } from '../../../utils/deactivateUser';
import { useState } from 'react';

export const UserRoleField = () => {
  const refresh = useRefresh();
  const record = useRecordContext();
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeactivate = async () => {
    setIsLoading(true);
    await deactivateUser(record.users_id, record.organization_id);
    refresh();
    setIsLoading(false);
    setShowDialog(false);
  };

  return (
    <>
      <FunctionField
        render={() => (
          <Chip
            sx={{
              mr: 1,
              mb: 1,
              border: '1px solid rgba(0, 0, 0, 0.1)',
            }}
            size="medium"
            label={
              <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                <SelectField source="roles_id" choices={USER_ROLE_CHOICES} variant="inherit" />
                <Typography component="span" variant="inherit">
                  at
                </Typography>
                <OrganizationReferenceField source="organization_id" />
                <Button onClick={() => setShowDialog(true)}>Deactivate</Button>
              </Box>
            }
          />
        )}
      />
      <Confirm
        isOpen={showDialog}
        loading={isLoading}
        title={`Deactivate user from org ${record.organization_id}`}
        content="Are you sure you want to deactivate user from org? If user belongs only to one org, it will be deactivated totally."
        onConfirm={handleDeactivate}
        onClose={() => setShowDialog(false)}
      />
    </>
  );
};
